import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import logoHeader from "../img/logo-header-resized.png";
import ciepLogo from "../img/new-ciep-logo-cropped.png";

// software icons
import tradosIcon from "../img/software/trados-badges-web-sdl-200x130-pine.jpg";
import finalDraftIcon from "../img/software/final draft.png";
import scrivenerIcon from "../img/software/scrivener-resized.png";
import proWritingAidIcon from "../img/software/prowritingaid.com.png";
import perfectItIcon from "../img/software/perfect-it.png";

export const AboutPageTemplate = ({
    image,
    title,
    description,
    header,
    members,
    content,
    contentComponent,
}) => {
    const PageContent = contentComponent || Content;

    return (
        <div className="content">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
            </Helmet>
            {/* banner image */}
            <div
                className="full-width-image margin-top-0"
                style={{
                    backgroundImage: `url(${
                        !!image.childImageSharp ? image.childImageSharp.fluid.src : image
                    })`,
                    // backgroundPosition: `top`,
                    backgroundPosition: `center`,
                    backgroundAttachment: `fixed`,
                }}
            >
                <div
                    id="main-header"
                    style={{
                        display: "flex",
                        lineHeight: "1",
                        justifyContent: "space-around",
                        alignItems: "left",
                        flexDirection: "column",
                    }}
                >
                    <img id="main-header_logo" src={logoHeader} alt="Read Panda logo header" />
                    {/* title */}
                    <h3
                        className="has-text-centered has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                        style={{
                            backgroundColor: "#b70d31",
                            color: "white",
                            lineHeight: "1",
                            padding: "0.25em",
                            width: "100%",
                            marginTop: "0",
                        }}
                    >
                        {header}
                    </h3>
                </div>
            </div>
            <section className="section section--gradient has-background-white has-text-black">
                <div className="container">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="section">
                                {/* <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{header}</h2> */}
                                <PageContent className="content has-text-justified" content={content} />
                                <br />
                                {/* members */}
                                {members.map((member, i) => (
                                    <div key={`m-card_${i}`} className="card">
                                        <div className="card-content about-card">
                                            <div className="about-card_upper">
                                                <img
                                                    className="about-card_upper_image"
                                                    src={
                                                        Boolean(member.image && member.image.childImageSharp)
                                                            ? member.image.childImageSharp.fixed.src
                                                            : member.image
                                                    }
                                                    alt={`${member.name} avatar`}
                                                    style={{
                                                        height: "100px",
                                                        width: "100px",
                                                    }}
                                                />
                                                <div className="about-card_upper_name-title-ciep">
                                                    <div className="about-card_upper_name-title-ciep_titles">
                                                        <p className="title is-4 mb-0">{member.name}</p>
                                                        <p className="title is-6 mb-0">{member.title}</p>
                                                    </div>
                                                    <img
                                                        className="about-card_upper_name-title-ciep_ciep-logo"
                                                        src={ciepLogo}
                                                        alt={`CIEP logo`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="content has-text-justified">
                                                {member.description}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* software icons */}
                            <div className="software-icons">
                                <a
                                    href="https://www.sdl.com/products-and-solutions/translation/software/sdl-trados-studio/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="SDL Trados"
                                    alt="SDL Trados logo"
                                >
                                    <img src={tradosIcon}></img>
                                </a>
                                <a
                                    href="https://www.finaldraft.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Final Draft"
                                    alt="Final Draft logo"
                                >
                                    <img src={finalDraftIcon}></img>
                                </a>
                                <a
                                    href="https://prowritingaid.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="ProWritingAid"
                                    alt="ProWritingAid logo"
                                >
                                    <img src={proWritingAidIcon}></img>
                                </a>
                                <a
                                    href="https://www.literatureandlatte.com/scrivener/overview"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Scrivener"
                                    alt="Scrivener logo"
                                >
                                    <img src={scrivenerIcon}></img>
                                </a>
                                <a
                                    href="https://intelligentediting.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="PerfectIt"
                                    alt="PerfectIt logo"
                                >
                                    <img src={perfectItIcon}></img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

AboutPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            title: PropTypes.string,
            description: PropTypes.string,
        })
    ),
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    content: PropTypes.string,
    contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <AboutPageTemplate
                image={post.frontmatter.image}
                members={post.frontmatter.members}
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                description={post.frontmatter.description}
                header={post.frontmatter.header}
                content={post.html}
            />
        </Layout>
    );
};

AboutPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
    query AboutPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                description
                header
                members {
                    name
                    image {
                        childImageSharp {
                            fixed(width: 100, height: 100) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                    title
                    description
                }
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
